<template>
  <div>
    <div class="projects-title">Some of my latest projects</div>
    <div class="projects-container">
      <a
        href="https://barespace.io/"
        target="_blank"
        class="project-details"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="50"
      >
        <div class="project-details">
          <div class="image-container">
            <img src="@/assets/images/barespace.png" alt="barespace" />
          </div>
          <h4>Barespace</h4>
          <h6>Optimize beauty brand platform</h6>
          <ul>
            <li>Web application</li>
            <li>Manegment panel</li>
          </ul>
        </div>
      </a>
      <a
        href="https://www.endlessfairs.com/"
        target="_blank"
        class="project-details"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="50"
      >
        <div class="project-details">
          <div class="image-container">
            <img
              src="@/assets/images/endlessfairs-logo.png"
              alt="endlessfairs"
            />
          </div>
          <h4>Endlessfairs</h4>
          <h6>Virtual fair platform</h6>
          <ul>
            <li>Web application</li>
          </ul>
        </div>
      </a>
      <a
        href="https://bixos.io/"
        target="_blank"
        class="project-details"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="50"
      >
        <div class="project-details">
          <div class="image-container">
            <img src="@/assets/images/bixos-icon.svg" alt="bixos" />
          </div>
          <h4>Bixos</h4>
          <h6>Blockchain technologies</h6>
          <ul>
            <li>Web application</li>
            <li>Manegment panel</li>
          </ul>
        </div>
      </a>
      <a
        href="https://mashinbank.com"
        target="_blank"
        class="project-details"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="50"
      >
        <div class="project-details">
          <div class="image-container">
            <img src="@/assets/images/mb-logo.png" alt="mashin bank" />
          </div>
          <h4>Mashin bank</h4>
          <h6>Car sales platform</h6>
          <ul>
            <li>Website</li>
            <li>Web application</li>
            <li>Manegment panel</li>
          </ul>
        </div>
      </a>
      <a
        href="https://edutech.co"
        target="_blank"
        class="project-details"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="50"
      >
        <div class="project-details">
          <div class="image-container">
            <img src="@/assets/images/edutech.png" alt="edutech" />
          </div>
          <h4>Edutech</h4>
          <h6>Investment platform</h6>
          <ul>
            <li>Website</li>
            <li>Manegment panel</li>
            <li>Blog</li>
          </ul>
        </div>
      </a>
      <a
        href="https://adilar.com/"
        target="_blank"
        class="project-details"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="50"
      >
        <div class="project-details">
          <div class="image-container">
            <img src="@/assets/images/adilar-logo.png" alt="edutech" />
          </div>
          <h4>Adilar</h4>
          <h6>Recruitment process management system</h6>
          <ul>
            <li>Manegment panel</li>
            <li>Crm</li>
          </ul>
        </div>
      </a>
      <!-- <router-link
        to="/demos"
        class="project-details"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="50"
      >
        <div class="project-details">
          <div class="image-container">
            <img src="@/assets/images/demo.png" alt="edutech" />
          </div>
          <h4>Demo projects</h4>
        </div>
      </router-link> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>
